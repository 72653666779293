import { CommunicationStatus } from '@120wateraudit/communications-types'
import {CommunicationV2} from "src/types/CommunicationsV2";

export const layoutContainerReducerV2 = (
  state: State,
  action: LayoutContainerActions
): State => {
  if (action.type === DispatchType.EditorChangesCleanup) {
    return {
      ...state,
      hasEditorChanges: undefined
    }
  }

  if (!state.hasEditorChanges || action.type === DispatchType.EditorChanges) {
    state = {
      ...state,
      hasEditorChanges: true
    }
  }

  if (action.type === DispatchType.PauseAndEdit) {
    return {
      ...state,
      status: action.payload.pauseAndEdit.status,
      isActive: false
    }
  }

  if (action.type === DispatchType.Name) {
    return {
      ...state,
      communication: {
        ...state.communication,
        name: action.payload
      } as CommunicationV2
    }
  }

  if (action.type === DispatchType.Event) {
    return {
      ...state,
      eventId: action.payload
    }
  }

  if (action.type === DispatchType.Status) {
    return {
      ...state,
      status: action.payload
    }
  }

  if (action.type === DispatchType.TriggerCondition) {
    return {
      ...state,
      triggerCondition: action.payload
    }
  }

  if (action.type === DispatchType.Error) {
    return {
      ...state,
      error: action.payload
    }
  }

  if (action.type === DispatchType.ErrorCleanup) {
    return {
      ...state,
      error: undefined
    }
  }

  return state
}

export enum DispatchType {
  Name,
  Event,
  TriggerCondition,
  Status,
  EditorChanges,
  EditorChangesCleanup,
  Error,
  ErrorCleanup,
  PauseAndEdit
}

interface State {
  communication: CommunicationV2
  eventId?: number
  triggerCondition?: any
  status: CommunicationStatus
  isActive: boolean
  hasEditorChanges?: boolean
  error?: Error
}

interface NameAction {
  type: DispatchType.Name
  payload: string
}

interface EventAction {
  type: DispatchType.Event
  payload: number
}

interface TriggerConditionAction {
  type: DispatchType.TriggerCondition
  payload: string
}

interface StatusAction {
  type: DispatchType.Status
  payload: CommunicationStatus
}

interface EditorChangesAction {
  type: DispatchType.EditorChanges
}

interface EditorChangesCleanupAction {
  type: DispatchType.EditorChangesCleanup
}

interface ErrorAction {
  type: DispatchType.Error
  payload?: Error
}

interface ErrorCleanupAction {
  type: DispatchType.ErrorCleanup
}

interface PauseAndEditAction {
  type: DispatchType.PauseAndEdit
  payload: {
    pauseAndEdit: CommunicationV2
  }
}

export type LayoutContainerActions =
  | NameAction
  | EventAction
  | TriggerConditionAction
  | StatusAction
  | EditorChangesAction
  | EditorChangesCleanupAction
  | ErrorAction
  | ErrorCleanupAction
  | PauseAndEditAction
