import {
  AccountType,
  Activity,
  Contact,
  Document,
  DocumentRelationType,
  DocumentType,
  Note
} from '@120wateraudit/envirio-components/dist/models'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import * as FileSaver from 'file-saver'
import { ImageFile } from 'react-dropzone'
import { BatchType, BulkImportType } from './modules/FileProcessor/dataAccess'

import { buildQueryString } from './utils/buildQueryString'
import { isEmptyObject } from './utils/isEmptyObject'
import { LocalStorageItem, getItem } from './utils/localStorage'
import { pushRoute } from './utils/navigation'

// Empty strings on local development will use proxy from package.json
export const AWS_BASE = process.env.REACT_APP_AWS_ENDPOINT || ''

export const client = axios.create({
  baseURL: AWS_BASE,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
})

interface ApiError {
  data: {
    errors?: { constraints: Record<string, string> }[]
  }
}

export const isApiError = (error: unknown): error is ApiError => {
  return (error as ApiError).data !== undefined
}

const handleApiError = (error: AxiosError): AxiosError => {
  if (
    error &&
    error.response &&
    error.response.status &&
    error.response.status === 401
  ) {
    setTimeout(() => pushRoute('/login'), 1000)
  }
  return error
}

export const getDefaultHeaders = () => {
  const token = getItem(LocalStorageItem.TOKEN)
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

interface AccountLevelRequest {
  accountId: number
}

interface ProgramTotalsRequest {
  accountId: number
  programId: number
}

export async function get<T = any>(
  url: string,
  config?: AxiosRequestConfig
): Promise<undefined | T> {
  try {
    const response = await client.get(url, config || getDefaultHeaders())
    return response.data
  } catch (error) {
    handleApiError(error)
    return undefined
  }
}

export const post = (url: string, data: any, config?: AxiosRequestConfig) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(url, data, {
        ...getDefaultHeaders(),
        ...config
      })
      resolve(response.data)
    } catch (error) {
      reject(handleApiError(error))
    }
  })
}

export const put = (url: string, data: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.put(url, data, getDefaultHeaders())
      resolve(response.data)
    } catch (error) {
      reject(handleApiError(error))
    }
  })
}

export const hardDelete = (url: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.delete(url, getDefaultHeaders())
      resolve(response.data)
    } catch (error) {
      reject(handleApiError(error))
    }
  })
}

export const getExcel = (url: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      client
        .get(url, {
          headers: {
            ...getDefaultHeaders().headers,
            Accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          },
          responseType: 'arraybuffer'
        })
        .then((response: AxiosResponse) =>
          resolve({
            data: response.data,
            headers: response.headers
          })
        )
    } catch (error) {
      reject(handleApiError(error))
    }
  })
}

export const postExcel = (url: string, data: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      client
        .post(url, data, {
          headers: {
            ...getDefaultHeaders().headers,
            Accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          },
          responseType: 'arraybuffer'
        })
        .then((response: AxiosResponse) =>
          resolve({
            data: response.data,
            headers: response.headers
          })
        )
    } catch (error) {
      reject(handleApiError(error))
    }
  })
}

export const downloadDocument = async (
  url: string,
  fileName: string,
  accept = 'application/octet-stream',
  additionalHeaders?: { [key: string]: any }
) => {
  try {
    const response = await client.get(url, {
      headers: {
        ...getDefaultHeaders().headers,
        Accept: accept,
        ...additionalHeaders
      },
      responseType: 'blob'
    })
    FileSaver.saveAs(response.data, fileName)
  } catch (error) {
    handleApiError(error)
  }
}

export const getTemplateData = async (
  batchType: BatchType,
  templateType: BulkImportType,
  fileType: 'csv' | 'xlsx'
) => {
  let url = `/file-processor/templates/${templateType.toLowerCase().toString()}`
  let contentType = 'text/csv'
  if (fileType === 'xlsx') {
    url = `${url}/xlsx`
    contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  } else {
    url = `${url}/${batchType.toString()}`
  }
  let response
  try {
    response = await client.get(url, {
      headers: {
        ...getDefaultHeaders().headers,
        Accept: contentType
      },
      responseType: 'blob'
    })
  } catch (error) {
    return handleApiError(error)
  }
  return response.data
}

export const downloadKitResultLetters = async ({
  accountId,
  kitIds,
  markCompleteOnResultLetterSent
}: {
  accountId: number
  kitIds: number[]
  markCompleteOnResultLetterSent: boolean
}): Promise<any> => {
  const url = `/pws/rest/kits/download-kit-result`
  const response = await post(
    url,
    {
      accountId,
      kitIds,
      markCompleteOnResultLetterSent
    },
    {
      headers: {
        ...getDefaultHeaders().headers,
        Accept: 'application/json'
      }
    }
  )
  return response
}

// Users
export const getCurrentUser = () => {
  return get(`/platform/account-management/rest/currentuser/@me`)
}

export const getCurrentUsersAccounts = () => {
  return get(
    `/platform/account-management/rest/useraccounts/@me?type=${AccountType.PWS}`
  )
}

export const getWeeklyExceedances = ({ accountId }: AccountLevelRequest) => {
  return get(
    `/pws/rest/accounts/${accountId}/programhub/weeklystats/exceedances`
  )
}

export const getWeeklyOrderedSamples = ({ accountId }: AccountLevelRequest) => {
  return get(
    `/pws/rest/accounts/${accountId}/programhub/weeklystats/orderedsamples`
  )
}

export const getWeeklyAnalyzedSamples = ({
  accountId
}: AccountLevelRequest) => {
  return get(
    `/pws/rest/accounts/${accountId}/programhub/weeklystats/analyzedsamples`
  )
}

// Locations
export const fetchAccountLocations = ({
  accountId,
  pageSize
}: {
  accountId: number
  pageSize?: number
}) => {
  const query = pageSize ? buildQueryString({ pageSize }) : ''
  return get(`/pws/rest/accounts/${accountId}/locations${query}`)
}

export const fetchAccountLocation = ({
  accountId,
  locationId
}: {
  accountId: number
  locationId: number
}) => {
  return get(
    `/pws/rest/accounts/${accountId}/locations/${locationId}?includeCollections=1`
  )
}

export const downloadBatch = (accountId: number, importBatchId: number) => {
  const url = `pws/rest/accounts/${accountId}/import-batch/report/${importBatchId}`
  return new Promise<void>(async (resolve, reject) => {
    try {
      const response = await client.get(url, {
        headers: {
          ...getDefaultHeaders().headers,
          Accept: 'application/octet-stream'
        },
        responseType: 'blob'
      })
      const fileName = `BatchFileReport-${importBatchId}.csv`
      FileSaver.saveAs(response.data, fileName)
      resolve()
    } catch (error) {
      reject(handleApiError(error))
    }
  })
}

export const getAssetsImportTemplate = ({
  accountId
}: {
  accountId: number
}) => {
  return downloadDocument(
    `/pws/rest/accounts/${accountId}/assets-import/template`,
    'pwsBulkImportAssetsTemplate.csv'
  )
}

export const createAccountLocation = ({
  accountId,
  contact,
  createSamplesOnImport,
  location,
  programId,
  samplingEventId
}: {
  accountId: number
  contact?: Contact
  createSamplesOnImport: boolean
  location: any
  programId?: number
  samplingEventId?: number
}) => {
  return programId
    ? post(
        // This endpoint is used to create locations in an event.
        `/pws/rest/accounts/${accountId}/programs/${programId}/samplingevents/${samplingEventId}`,
        { contact, createSamplesOnImport, location }
      )
    : post(`/pws/rest/accounts/${accountId}/locations`, {
        // This endpoint creates at the top level locations page
        contact,
        createSamplesOnImport: false,
        location
      })
}

export const createLocationSamples = ({
  accountId,
  locationIds,
  programId,
  samplingEventId
}: {
  accountId: number
  locationIds: number[]
  programId: number
  samplingEventId: number
}) => {
  return post(
    `/pws/rest/accounts/${accountId}/programs/${programId}/samplingevents/${samplingEventId}/createsamples`,
    locationIds
  )
}

export const updateAccountLocation = async ({
  accountId,
  id,
  location
}: {
  accountId: number
  id: number
  location: any
}) => {
  return await put(`/pws/rest/accounts/${accountId}/locations/${id}`, location)
}

export const updateLocationNote = ({
  accountId,
  locationId,
  note
}: {
  accountId: number
  locationId: number
  note: Note
}) => {
  return put(
    `/pws/rest/accounts/${accountId}/locations/${locationId}/note`,
    note
  )
}

export const deleteLocationNote = ({
  accountId,
  id,
  locationId
}: {
  accountId: number
  id: number
  locationId: number
}) => {
  return hardDelete(
    `/pws/rest/accounts/${accountId}/locations/${locationId}/note/${id}`
  )
}

// Lead Service Lines
export const fetchLeadServiceLine = ({
  accountId,
  id
}: {
  accountId: number
  id: number
}) => {
  return get(`/pws/rest/accounts/${accountId}/leadservicelines/${id}`)
}

export const fetchLeadServiceLines = ({
  accountId,
  locationId,
  programId
}: {
  accountId: number
  locationId?: number
  programId?: number
}) => {
  let baseUrl = `/pws/rest/accounts/${accountId}/leadservicelines`
  if (locationId || programId) {
    baseUrl += '?'
    baseUrl += locationId ? `locationId=${locationId}&` : ''
    baseUrl += programId ? `programId=${programId}&` : ''
    baseUrl = baseUrl.slice(0, -1)
  }
  return get(baseUrl)
}

export const createLeadServiceLine = ({
  accountId,
  leadServiceLine
}: {
  accountId: number
  leadServiceLine: any
}) => {
  return post(
    `/pws/rest/accounts/${accountId}/leadservicelines`,
    leadServiceLine
  )
}

export const updateLeadServiceLine = ({
  accountId,
  id,
  leadServiceLine
}: {
  accountId: number
  id: number
  leadServiceLine: any
}) => {
  return put(
    `/pws/rest/accounts/${accountId}/leadservicelines/${id}`,
    leadServiceLine
  )
}

export const deleteLeadServiceLine = ({
  accountId,
  id
}: {
  accountId: number
  id: number
}) => {
  return hardDelete(`/pws/rest/accounts/${accountId}/leadservicelines/${id}`)
}

// Programs
export const fetchAccountPrograms = ({ accountId }: AccountLevelRequest) => {
  return get(`/pws/rest/accounts/${accountId}/programs`)
}

// Program Totals
export const fetchProgramTotals = ({
  accountId,
  programId
}: ProgramTotalsRequest) => {
  return get(`/pws/rest/accounts/${accountId}/programs/${programId}/totals`)
}

export const fetchAccountProgram = ({
  accountId,
  programId
}: {
  accountId: number
  programId: number
}) => {
  return get(`/pws/rest/accounts/${accountId}/programs/${programId}`)
}

export const createAccountProgram = ({
  accountId,
  program
}: {
  accountId: number
  program: any
}) => {
  return post(`/pws/rest/accounts/${accountId}/programs`, program)
}

export const updateAccountProgram = ({
  accountId,
  id,
  program
}: {
  accountId: number
  id: number
  program: any
}) => {
  return put(`/pws/rest/accounts/${accountId}/programs/${id}`, program)
}

export const deleteAccountProgram = ({
  accountId,
  id
}: {
  accountId: number
  id: number
}) => {
  return hardDelete(`/pws/rest/accounts/${accountId}/programs/${id}`)
}

export const getProgramTotals = ({
  accountId,
  programId
}: {
  accountId: number
  programId: number
}) => {
  if (!programId) {
    return
  }

  return get(`/pws/rest/accounts/${accountId}/programs/${programId}/totals`)
}

// Sampling Events
export const fetchAccountSamplingEvents = ({
  accountId
}: {
  accountId: number
}) => {
  return get(`/pws/rest/accounts/${accountId}/samplingevents`)
}

export const fetchProgramSamplingEvents = ({
  accountId,
  programId
}: {
  accountId: number
  programId: number
}) => {
  return get(
    `/pws/rest/accounts/${accountId}/samplingevents?programId=${programId}`
  )
}

export const fetchAccountSamplingEvent = ({
  accountId,
  samplingEventId
}: {
  accountId: number
  samplingEventId: number
}) => {
  return get(
    `/pws/rest/accounts/${accountId}/samplingevents/${samplingEventId}`
  )
}

export const createAccountSamplingEvent = ({
  accountId,
  samplingEvent
}: {
  accountId: number
  samplingEvent: any
}) => {
  return post(`/pws/rest/accounts/${accountId}/samplingevents`, samplingEvent)
}

export const updateAccountSamplingEvent = ({
  accountId,
  id,
  samplingEvent
}: {
  accountId: number
  id: number
  samplingEvent: any
}) => {
  return put(
    `/pws/rest/accounts/${accountId}/samplingevents/${id}`,
    samplingEvent
  )
}

export const deleteAccountSamplingEvent = ({
  accountId,
  id
}: {
  accountId: number
  id: number
  samplingEvent: number
}) => {
  return hardDelete(`/pws/rest/accounts/${accountId}/samplingEvents/${id}`)
}

// Samples
export const fetchSamples = ({ accountId }: { accountId: number }) => {
  return get(`/pws/rest/accounts/${accountId}/samples`)
}

export const fetchSample = ({
  accountId,
  sampleId
}: {
  accountId: number
  sampleId: number
}) => {
  return get(`/pws/rest/accounts/${accountId}/samples/${sampleId}`)
}

export const createInvalidatedSample = ({
  accountId,
  sample,
  sampleId
}: {
  accountId: number
  sample: any
  sampleId: number
}) => {
  return post(
    `/pws/rest/accounts/${accountId}/samples/${sampleId}/invalidate`,
    sample
  )
}

export const completeSample = ({
  accountId,
  sample,
  sampleId
}: {
  accountId: number
  sample: any
  sampleId: number
}) => {
  return post(
    `/pws/rest/accounts/${accountId}/samples/${sampleId}/complete`,
    sample
  )
}

export const completeSamples = ({
  accountId,
  sampleIds
}: {
  accountId: number
  sampleIds: number | number[]
}) => {
  if (typeof sampleIds === 'number') {
    sampleIds = [sampleIds]
  }

  return post(`/pws/rest/accounts/${accountId}/samples/complete`, {
    sampleIds
  })
}

export const moveSamples = ({
  accountId,
  otherReasonValue,
  reasonForMove,
  sampleIds,
  samplingEventId
}: {
  accountId: number
  otherReasonValue?: string
  reasonForMove: string
  sampleIds: number[]
  samplingEventId: number
}) => {
  if (typeof sampleIds === 'number') {
    sampleIds = [sampleIds]
  }

  return post(`/pws/rest/accounts/${accountId}/samples/move`, {
    otherReasonValue,
    reasonForMove,
    sampleIds,
    samplingEventId
  })
}

export const updateSample = ({
  accountId,
  sample,
  sampleId
}: {
  accountId: number
  sample: any
  sampleId: number
}) => {
  return put(`/pws/rest/accounts/${accountId}/samples/${sampleId}`, sample)
}

export const fetchActivityTypes = ({ accountId }: { accountId: number }) => {
  return get(`/pws/rest/accounts/${accountId}/activitytypes`)
}

export const fetchActivities = ({
  accountId,
  queryMap
}: {
  accountId: number
  queryMap?: any
}) => {
  let url = `/pws/rest/accounts/${accountId}/activities`
  if (!isEmptyObject(queryMap)) {
    url += buildQueryString(queryMap)
  }
  return get(url)
}

export const createActivity = ({
  accountId,
  activity
}: {
  accountId: number
  activity: Activity
}) => {
  return post(`/pws/rest/accounts/${accountId}/activities`, activity)
}

export const deleteActivity = ({
  accountId,
  activityId
}: {
  accountId: number
  activityId: number
}) => {
  return hardDelete(`/pws/rest/accounts/${accountId}/activities/${activityId}`)
}

export const updateActivity = ({
  accountId,
  activity
}: {
  accountId: number
  activity: Activity
}) => {
  return put(
    `/pws/rest/accounts/${accountId}/activities/${activity.id}`,
    activity
  )
}

export const fetchSkus = ({
  accountId,
  queryMap
}: {
  accountId: number
  queryMap: {
    programTypeId: number
  }
}) => {
  let url = `/pws/rest/accounts/${accountId}/skus`
  if (!isEmptyObject(queryMap)) {
    url += buildQueryString(queryMap)
  }
  return get(url)
}

export const fetchProtocols = ({
  accountId,
  queryMap
}: {
  accountId: number
  queryMap: {
    eventId: number
  }
}) => {
  let url = `/pws/rest/accounts/${accountId}/protocols`
  if (!isEmptyObject(queryMap)) {
    url += buildQueryString(queryMap)
  }
  return get(url)
}

export const uploadPublicFile = ({
  accountId,
  file,
  relatedType,
  relatedTypeId
}: {
  accountId: number
  file: ImageFile
  relatedType: DocumentRelationType
  relatedTypeId: DocumentType
}) => {
  const formData = new FormData()
  formData.append('file', file)

  return post(
    `/platform/documents/accounts/${accountId}/documents/public/${relatedType}/${relatedTypeId}`,
    formData
  )
}

export const getArchivedLetters = async ({
  accountId,
  communicationInstanceId
}: {
  accountId: number
  communicationInstanceId: number
}) => {
  const data = await get<Document[]>(
    `/platform/documents/accounts/${accountId}/documents/${DocumentRelationType.LetterArchive}/${communicationInstanceId}`
  )
  return data || []
}
