import { ActiveFilters, Sort } from 'src/components/Table/types' // Should these still be in the table?

export type Maybe<T> = T | void

export enum Tags {
  AdditionalInfo = 'Additional Info',
  AccountResources = 'Account Resources',
  Comments = 'Comments',
  Communications = 'Communications',
  CommunicationBatches = 'CommunicationBatches',
  CommunicationInstances = 'CommunicationInstances',
  CommunicationTemplates = 'CommunicationTemplates',
  Events = 'Events',
  ExternalSubmissions = 'ExternalSubmissions',
  Programs = 'Programs',
  WorkOrders = 'WorkOrders',
  Locations = 'Locations',
  GeoserverInventory = 'GeoserverInventory',
  Activities = 'Activities',
  Documents = 'Documents',
  Attachments = 'Attachments',
  Accounts = 'Accounts',
  System = 'System',
  Submissions = 'Submissions',
  SubmissionPeriods = 'Submission Periods',
  Users = 'Users',
  EntityDefinitions = 'EntityDefinitions',
  CustomFieldDefinitions = 'CustomFieldDefinitions'
}

export interface SearchParams {
  activeFilters: ActiveFilters
  customFields?: string
  include?: string
  page: number
  pageSize: number
  searchTerm?: string
  sort?: Sort | string
}

export interface PaginatedResponse<T> {
  count: number
  items: T[]
  pageNumber: number
  pageSize: number
  pages: number
}

export interface PaginatedResponseV2<T> {
  count: number
  data: T[]
  pageNumber: number
  pageSize: number
  pages: number
}

export interface PaginatedResponseV3<T> {
  data: T[]
  pageNumber: number
  pageSize: number
  totalRecords: number
}

export interface ApiResponse<T> {
  data: T
}
