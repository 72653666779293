import { Loader, Modal } from '@120wateraudit/envirio-components'
import React, { Suspense, memo, useEffect, useReducer, useState } from 'react'

import { CommunicationStatus } from '@120wateraudit/communications-types'
import { useLazyQuery } from '@apollo/client'
import { contactsClient } from 'src/apollo-clients'
import {
  COMMUNICATION_PREVIEW,
  CommunicationPreviewData,
  CommunicationPreviewVariables
} from 'src/components/Communications/PDFPreview/dataAccess'
import {
  DispatchType,
  layoutContainerReducerV2
} from 'src/containers/Communications/EditorSuite/layoutContainerReducerV2'
import {CommunicationV2} from "src/types/CommunicationsV2";

const PDFModal = React.lazy(
  () => import('src/components/Communications/PDFPreviewV2')
)

interface PDFPreviewContainerV2Props {
  accountId: number
  data: CommunicationV2
  isOpen: boolean
  setErrorMessage: React.Dispatch<React.SetStateAction<undefined | string>>
  setZIndex: React.Dispatch<React.SetStateAction<number>>
  closePreview: () => void
  toggle: () => void
}

interface State {
  communication: CommunicationV2
  content: string
}

const PDFModalContainerV2: React.FC<PDFPreviewContainerV2Props> = ({
  accountId,
  closePreview,
  data,
  isOpen,
  setErrorMessage,
  setZIndex,
  toggle
}) => {
  const [state, setState] = useState<undefined | State>(undefined)
  const [error, dispatch] = useReducer(layoutContainerReducerV2, {
    communication: data,
    isActive: data.status === CommunicationStatus.Active,
    status: data.status || CommunicationStatus.Draft,
    triggerCondition: undefined
  })
  
  const createCommunicationPreview = (): State => {
    return {
      communication: data,
      content: data.content ? JSON.stringify(data.content) : ''
    }
  }

  const [
    previewQuery,
    { data: previewData, error: previewError, loading: previewLoading }
  ] = useLazyQuery<CommunicationPreviewData, CommunicationPreviewVariables>(
    COMMUNICATION_PREVIEW,
    {
      client: contactsClient,
      fetchPolicy: 'network-only',
      variables: {
        accountId,
        ...(state as State)
      }
    }
  )

  useEffect(() => {
    setErrorMessage(undefined)
    const runQuery = async () => {
      const params = await createCommunicationPreview()
      setZIndex(-1)
      setState(params)

      previewQuery()
    }
    runQuery()
  }, [])

  useEffect(() => {
    setZIndex(1000)
    if (previewError) {
      setErrorMessage(previewError.graphQLErrors[0].message)
    }
  }, [previewError])

  useEffect(() => {
    if (previewError) {
      setTimeout(() => {
        dispatch({
          type: DispatchType.ErrorCleanup
        })
      }, 5000)
    }
  }, [error])

  const name = data.name || data.communicationType
  const title = `${name}-${new Date().toISOString()}`

  return (
    <>
      {!previewLoading && previewData && (
        <Suspense fallback={<></>}>
          <PDFModal
            isOpen={isOpen}
            loading={previewLoading}
            template={previewData && previewData.communicationPreview}
            title={title}
            toggle={closePreview}
          />
        </Suspense>
      )}
      {previewLoading && !previewError && (
        <Modal
          content={<Loader />}
          isOpen
          style={{
            content: {
              minHeight: '45%'
            }
          }}
          toggle={toggle}
        />
      )}
    </>
  )
}

export default memo(PDFModalContainerV2)
