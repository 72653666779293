import { createApi } from '@reduxjs/toolkit/dist/query/react'

import { pwsClient } from 'src/apollo-clients'
import axiosBaseQuery from './axiosBaseQuery'
import graphQLBaseQuery from './graphQLBaseQuery'
import { Tags } from './types'
import {BulkActionType} from "src/types/enums/BulkActionType";
import {
  AssetStatistics,
  BulkAssignResponse,
  SelectAllParams
} from "src/modules/ServiceLines/dataAccess";

// We create an empty API here to allow for code-splitting.
// Individual endpoints are created in the `dataAccess` files of modules.
export const pwsApi = createApi({
  // The axiosBaseQuery pulls the account ID automatically making the full base URL `/pws/rest/accounts/${id}`
  baseQuery: axiosBaseQuery({ baseUrl: `/pws/rest/accounts/` }),
  reducerPath: 'pwsApi',
  tagTypes: [Tags.Events, Tags.Programs, Tags.Locations, Tags.Activities, Tags.CustomFieldDefinitions],
  endpoints: () => ({})
})

export const pwsApiV2 = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: `/pws/accounts/` }),
  reducerPath: 'pwsApiV2',
  tagTypes: [Tags.WorkOrders, Tags.Locations],
  endpoints: builder => ({
    getAssetStats: builder.query<AssetStatistics, void>({
      query: () => ({ url: '/assets/statistics' })
    }),
    performBulkActionByType: builder.mutation<
        BulkAssignResponse,
        SelectAllParams
    >({
      query: ({
                accountType,
                bulkActionType,
                communicationId,
                download,
                eventId,
                isCertifiedMail,
                programId,
                paramsURL,
                locationIds
              }) => {
        const params = paramsURL ? new URLSearchParams(paramsURL) : new URLSearchParams()
        const body = {}
        params.forEach((value:string, key: string): void => {
          body[key] = value
        })
        if (
            bulkActionType === BulkActionType.AssignLocations &&
            programId &&
            eventId
        ) {
          body['programId'] = programId
          body['eventId'] = eventId
        }

        if (
            bulkActionType === BulkActionType.SendCommunications &&
            accountType &&
            communicationId
        ) {
          body['accountType'] = accountType
          body['communicationId'] = communicationId.toString()
          if (isCertifiedMail) {
            body['isCertifiedMail'] = isCertifiedMail
          }
          if (download) {
            body['download'] = download
          }
          if (locationIds) {
            body['locationIds'] = locationIds
          }
          if (programId) {
            body['programId'] = programId
          }
          if (eventId) {
            body['eventId'] = eventId
          }
        }

        return {
          method: 'post',
          url: `/assets/ServiceLine/bulk-action/${bulkActionType}?${params.toString()}`,
          data: body
        }
      }
    })
  })
})

export const pwsApiV3 = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: `/pws/rest/`,
    skipAccount: true
  }),
  reducerPath: 'pwsApiV3',
  tagTypes: [Tags.AccountResources],
  endpoints: () => ({})
})

export const pwsGQL = createApi({
  baseQuery: graphQLBaseQuery({ client: pwsClient }),
  reducerPath: 'pwsGQL',
  tagTypes: [Tags.Activities, Tags.Accounts],
  endpoints: () => ({})
})

export default pwsApi

export const {
  useGetAssetStatsQuery,
  usePerformBulkActionByTypeMutation
} = pwsApiV2
