import { Button } from '@120wateraudit/envirio-components'
import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'

import { ReactComponent as LetterIcon } from 'src/assets/images/Letter.svg'
import ResultImg from 'src/assets/images/ResultLetterIcon.svg'
import { ModalContent, ModalHeading } from 'src/modules/Modal/shared'
import { ModalWrapper } from 'src/modules/Samples/modals/CompleteSamplesModal/shared'
import { ModalContext } from 'src/modules/Modal'
import { Kit } from 'src/types/Kit'
import { Row } from 'src/components/Layout'
import { Flags, useConfig } from 'src/hooks'
import { PWSProgram } from '@120wateraudit/envirio-components/dist/models'
import { SamplingEvent } from 'src/types/SamplingEvents'

const KitsCommunicationModal: React.FC<Props> = ({
  accountId,
  hideInventoryChoices,
  locationIds,
  locations,
  onSendCommsModalCompletion,
  onSendResultsCompleted,
  selected,
  program,
  programEvent
}) => {
  const commsTwoEnabled = useConfig(Flags.CommsTwoEnabled)

  const { closeModal, openModal } = useContext(ModalContext)

  const openSendKitResultsModal = useCallback(() => {
    if (commsTwoEnabled) {
      // openModal('sendResultsModalTwo', {})
    } else {
      openModal('sendResultsModal', {
        closeModal,
        onSendResultsCompleted,
        selected
      })
    }
  }, [commsTwoEnabled, openModal, closeModal, onSendResultsCompleted, selected])

  const openSendCommunicationModal = useCallback(() => {
    if (commsTwoEnabled) {
      openModal('sendCommunicationTwo', {
        accountId,
        locationIds,
        locations,
        hideInventoryChoices,
        onCompletion: onSendCommsModalCompletion,
        programId: program?.id,
        eventId: programEvent?.id
      })
    } else {
      openModal('sendCommunication', {
        accountId,
        locationIds,
        hideInventoryChoices,
        onSendCommsModalCompletion
      })
    }
  }, [
    commsTwoEnabled,
    openModal,
    accountId,
    locationIds,
    onSendCommsModalCompletion,
    hideInventoryChoices
  ])

  return (
    <ModalWrapper>
      <ModalHeading>
        <h3 style={{ textDecoration: 'underline' }}>Send Communications</h3>
        <h4>Which type of letter would you like to send?</h4>
      </ModalHeading>

      <ModalContent style={{ gap: '2rem', width: '90%' }}>
        <Row style={{ gap: '2rem' }}>
          <Button
            style={{ height: '100%', width: '50%' }}
            onClick={openSendCommunicationModal}>
            <LetterIcon />
            <h4>Company Letterhead</h4>
          </Button>

          <Button
            style={{ height: '100%', width: '50%' }}
            onClick={openSendKitResultsModal}>
            <ResultIcon src={ResultImg} />
            <h4>Results Letter</h4>
          </Button>
        </Row>
      </ModalContent>
    </ModalWrapper>
  )
}

const ResultIcon = styled.img`
  height: 48px;
  width: 48px;
`

interface Props {
  accountId: number
  locationIds: number[]
  locations?: any
  hideInventoryChoices?: boolean
  onSendCommsModalCompletion: () => void
  onSendResultsCompleted: () => void
  selected: Kit[]
  program?: PWSProgram
  programEvent?: SamplingEvent
}

export default KitsCommunicationModal
