import React, { memo } from 'react'
import { CommunicationTypes } from 'src/containers/Locations/SendCommunicationButton'
import { Button } from 'src/modules/Modal/shared'
import { CommunicationV2 } from 'src/types/CommunicationsV2'

const SendModalFooter: React.FC<Props> = ({
  disabledForError,
  stepNumber,
  onNext,
  onBack,
  onSend,
  selectedCommunication,
  selectedCommunicationType,
  onDownload,
  isSaving,
  onClose
}) => {
  const isDownload =
    selectedCommunicationType === CommunicationTypes['Letter as PDF']

  if (stepNumber === 0) {
    return (
      <>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="primary"
          disabled={
            selectedCommunication === undefined ||
            !selectedCommunicationType ||
            disabledForError
          }
          onClick={onNext}>
          Next
        </Button>
      </>
    )
  }

  if (stepNumber === 1) {
    return (
      <>
        <Button onClick={onBack}>Back</Button>
        <Button
          variant="primary"
          onClick={isDownload ? onDownload : onSend}
          disabled={isSaving || disabledForError}>
          {isDownload ? 'Generate PDF' : isSaving ? 'Sending...' : 'Send'}
        </Button>
      </>
    )
  }

  return null
}

export interface Props {
  disabledForError: boolean
  stepNumber: number
  onNext: () => void
  onBack: () => void
  onSend: () => void
  onDownload: () => void
  selectedCommunication?: CommunicationV2
  selectedCommunicationType?: CommunicationTypes
  isSaving: boolean
  isSelectAllOn?: boolean
  locationIds: number[]
  onClose: () => void
}

export default memo(SendModalFooter)
