import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { AccountType } from '@120wateraudit/communications-types'

import { SearchParams, useData } from 'src/hooks'
import { useAccount } from 'src/router/UserProvider'
import { pwsApiV2 } from 'src/services'
import { Asset } from 'src/types/Asset'
import { YesNo } from 'src/types/enums/YesNo'
import { YesNoNA } from 'src/types/enums/YesNoNA'
import { BulkActionType } from 'src/types/enums/BulkActionType'
import { YesNoUnknown } from 'src/types/enums/YesNoUnknown'
import { isValidDate } from 'src/utils/isValidDate'
import { oneLineAddress } from 'src/utils/oneLineAddress'
import { convertEnumToListItems } from 'src/utils/selectlist'
import { get } from 'src/API'

enum Yes {
  Yes = 'Yes'
}

export const yesOption = convertEnumToListItems(Yes)

export const yesNoDropdownOptions = convertEnumToListItems(YesNo)

export const yesNoUnknownDropdownOptions = convertEnumToListItems(YesNoUnknown)

export const yesNoNADropdownOptions = convertEnumToListItems(YesNoNA)

export const ownershipTypeOptions = [
  {
    key: 1,
    text: 'The entire service line is owned by the water system',
    value: 'The entire service line is owned by the water system'
  },
  {
    key: 2,
    text: 'The entire service line is owned by the customer',
    value: 'The entire service line is owned by the customer'
  },
  {
    key: 3,
    text: 'Ownership is split, meaning that the system owns a portion and the customer owns a portion',
    value:
      'Ownership is split, meaning that the system owns a portion and the customer owns a portion'
  },
  {
    key: 4,
    text: 'Other',
    value: 'Other'
  }
]

export const initialInventoryOrUpdateOptions = [
  {
    key: 1,
    text: 'Initial Inventory',
    value: 'Initial Inventory'
  },
  {
    key: 2,
    text: 'Inventory Update',
    value: 'Inventory Update'
  }
]

export type AssetStatistics = {
  galvanizedAssets: number
  leadAssets: number
  nonLeadAssets: number
  totalAssets: number
  unknownAssets: number
}

export type SelectAllParams = {
  bulkActionType: BulkActionType
  paramsURL?: string
  programId?: number
  eventId?: number
  accountType?: AccountType
  communicationId?: string
  download?: boolean
  isCertifiedMail?: boolean
  templateId?: number
  locationIds?: number[]
}

export interface BulkAssignResponse {
  batchId?: number
  uniqueLocations: number
  locationIds: number[]
}

const DEFAULT_PARAMS = {
  include: ['location.leadServiceLinePredictions', 'location.profile'].join(',')
}

export const { useGetAssetStatsQuery, usePerformBulkActionByTypeMutation } =
  pwsApiV2

const handleOldParams = (params: SearchParams): SearchParams => {
  const clone = { ...params }
  if (typeof clone.sort === 'object') {
    const direction = clone.sort.direction === 'DESC' ? '-' : ''
    clone.sort = `${direction}${clone.sort.key}`
  }
  return clone
}

export function textValidationCheck(value?: string) {
  if (!value || (value && value.trim().length === 0)) {
    return true
  }

  return false
}

export const useAssets = (
  params: SearchParams,
  defaultParams?: Partial<SearchParams>
) => {
  const { id } = useAccount()
  const newParams = useMemo(() => handleOldParams(params), [params])
  const {
    data: assets,
    exportData: exportAssets,
    loading,
    paramsURL,
    refetch,
    total: totalAssets,
    mergedParams,
  } = useData<Asset>(`/pws/accounts/${id}/assets/ServiceLine`, newParams, {
    defaultParams: defaultParams || DEFAULT_PARAMS,
    exportPrefix: 'servicelines_export',
    exportUrl: `/file-processor/export/servicelines`,
    useNewExport: true,
    useNewFilters: true
  })
  return { assets, exportAssets, loading, paramsURL, refetch, totalAssets, mergedParams }
}

export const useStateSubmissionValidationStatus = (
  serviceLineId: number | string | undefined
): any => {
  const [stateValidationErrors, setStateValidationErrors] = useState<any>('{}')
  const [stateValidationErrorsList, setStateValidationErrorsList] = useState<
    string[]
  >([])

  const { id } = useAccount()

  useEffect(() => {
    const fetchData = async () => {
      if (!serviceLineId) {
        return
      }
      const slId = Number(serviceLineId)
      const url = `/pws/accounts/${id}/stateSubmissionValidationStatus/${slId}`
      const response = await get(url)
      const parse = JSON.parse(response.data?.errors || '{}')
      setStateValidationErrors(parse)
      const errorsList = parse?.fieldErrors?.map(({ field }) => field)
      setStateValidationErrorsList(errorsList)
    }
    fetchData()
  }, [])

  return { stateValidationErrors, stateValidationErrorsList }
}

export const useStateSubmissionInventoryValid = () => {
  const [isValid, setIsValid] = useState<boolean>(false)

  const { id } = useAccount()
  const url = `/pws/accounts/${id}/stateSubmissionInventoryValid`

  useEffect(() => {
    const fetchData = async () => {
      const response = await get(url)

      setIsValid(response)
    }
    fetchData()
  }, [isValid])

  return { isValid }
}

export const useStateSubmissionInventoryValidCount = () => {
  const [isValidCount, setIsValidCount] = useState<number>(0)

  const { id } = useAccount()
  const url = `/pws/accounts/${id}/stateSubmissionInventoryValidCount`

  useEffect(() => {
    const fetchData = async () => {
      const response = await get(url)

      setIsValidCount(response)
    }
    fetchData()
  }, [isValidCount, url])

  return { isValidCount }
}

export interface Values {
  address?: string
  description: string
  externalId: string
  fittingVerifiedBy: string
  fittingsVerificationMethod?: null | string
  fittingsVerifiedDate: null | Date
  leadFittings?: YesNoUnknown
  leadSolder?: YesNoUnknown
  locationId: string
  otherLeadEquipment?: string
  privateClassificationBasis?: string
  privateInstalledDate: null | Date
  privateLeadSolderLeadLined?: YesNoUnknown
  privateLineDepth: string
  privateLineDiameter: number | string
  privateDiameterOverTwoInches: boolean | null | string
  privateMaterial: number | string
  privateNotes?: string
  privateRemovalDate: null | Date
  privateVerificationMethod?: null | string
  privateVerified: boolean
  privateVerifiedBy: string
  privateVerifiedDate: null | Date
  publicClassificationBasis?: string
  publicInstalledDate: null | Date
  publicLeadSolderLeadLined?: YesNoUnknown
  publicLineDepth: string
  publicLineDiameter: number | string
  publicDiameterOverTwoInches: boolean | null | string
  publicMaterial: number | string
  publicNotes?: string
  publicPreviouslyLead?: YesNoUnknown
  publicRemovalDate: null | Date
  publicVerificationMethod?: null | string
  publicVerified: boolean
  publicVerifiedBy: string
  publicVerifiedDate: null | Date
}

function dateOrNull(date?: string) {
  return date ? moment.utc(date).toDate() : null
}

export const useInitialValues = (serviceLine?: Asset): Values => {
  return useMemo(() => {
    const asset = serviceLine?.serviceLineAsset
    const address =
      serviceLine && serviceLine.location
        ? oneLineAddress(serviceLine.location)
        : ''

    return {
      address,
      description: serviceLine?.description ?? '',
      externalId: serviceLine?.externalId ?? '',
      fittingVerifiedBy: asset?.fittingVerifiedBy ?? '',
      fittingsVerificationMethod: asset?.fittingsVerificationMethod ?? null,
      fittingsVerifiedDate: dateOrNull(asset?.fittingsVerifiedDate),
      leadFittings: asset?.leadFittings ?? YesNoUnknown.Unknown,
      leadSolder: asset?.leadSolder ?? YesNoUnknown.Unknown,
      locationId: `${serviceLine?.location.id}` ?? '',
      otherLeadEquipment: asset?.otherLeadEquipment ?? '',
      privateClassificationBasis: asset?.privateClassificationBasis,
      privateInstalledDate: dateOrNull(asset?.privateInstalledDate),
      privateLeadSolderLeadLined: asset?.privateLeadSolderLeadLined,
      privateLineDepth: `${asset?.privateLineDepth ?? ''}`,
      privateLineDiameter: asset?.privateLineDiameter?.id ?? 1,
      privateDiameterOverTwoInches:
        asset?.privateDiameterOverTwoInches ?? 'Unknown',
      privateMaterial: asset?.privateMaterial?.id ?? 1,
      privateNotes: asset?.privateNotes,
      privateRemovalDate: dateOrNull(asset?.privateRemovalDate),
      privateVerificationMethod: asset?.privateVerificationMethod,
      privateVerified: asset?.privateVerified ?? false,
      privateVerifiedBy: asset?.privateVerifiedBy ?? '',
      privateVerifiedDate: dateOrNull(asset?.privateVerifiedDate),
      publicClassificationBasis: asset?.publicClassificationBasis,
      publicInstalledDate: dateOrNull(asset?.publicInstalledDate),
      publicLeadSolderLeadLined: asset?.publicLeadSolderLeadLined,
      publicLineDepth: `${asset?.publicLineDepth ?? ''}`,
      publicLineDiameter: asset?.publicLineDiameter?.id ?? 1,
      publicDiameterOverTwoInches:
        asset?.publicDiameterOverTwoInches ?? 'Unknown',
      publicMaterial: asset?.publicMaterial?.id ?? 1,
      publicNotes: asset?.publicNotes,
      publicPreviouslyLead: asset?.publicPreviouslyLead ?? YesNoUnknown.Unknown,
      publicRemovalDate: dateOrNull(asset?.publicRemovalDate),
      publicVerificationMethod: asset?.publicVerificationMethod,
      publicVerified: asset?.publicVerified ?? false,
      publicVerifiedBy: asset?.publicVerifiedBy ?? '',
      publicVerifiedDate: dateOrNull(asset?.publicVerifiedDate),
      ownership: asset?.ownership
    }
  }, [serviceLine])
}

export const validate = (values: Values) => {
  const errors: Record<string, string> = {}

  if (!values.locationId || values.locationId === 'undefined') {
    errors.locationId = 'Location is required'
  }

  if (!values.privateMaterial) {
    errors.privateMaterial = 'Customer-Owned material is required'
  }
  if (!values.publicMaterial) {
    errors.publicMaterial = 'System-Owned material is required'
  }

  if (values.description && values.description.length > 200) {
    errors.description = 'Description is too long'
  }

  const isPublicInstallDateValid = isValidDate(values.publicInstalledDate)
  if (values.publicInstalledDate && !isPublicInstallDateValid) {
    errors.publicInstalledDate = 'Invalid Date or Format, please use MM-DD-YYYY'
  }

  const isPublicVerifiedDateValid = isValidDate(values.publicVerifiedDate)
  if (values.publicVerifiedDate && !isPublicVerifiedDateValid) {
    errors.publicVerifiedDate = 'Invalid Date or Format, please use MM-DD-YYYY'
  }

  const isPublicRemovalDateValid = isValidDate(values.publicRemovalDate)
  if (values.publicRemovalDate && !isPublicRemovalDateValid) {
    errors.publicRemovalDate = 'Invalid Date or Format, please use MM-DD-YYYY'
  }

  const isPrivateInstallDateValid = isValidDate(values.privateInstalledDate)
  if (values.privateInstalledDate && !isPrivateInstallDateValid) {
    errors.privateInstalledDate =
      'Invalid Date or Format, please use MM-DD-YYYY'
  }

  const isPrivateVerifiedDateValid = isValidDate(values.privateVerifiedDate)
  if (values.privateVerifiedDate && !isPrivateVerifiedDateValid) {
    errors.privateVerifiedDate = 'Invalid Date or Format, please use MM-DD-YYYY'
  }

  const isPrivateRemovalDateValid = isValidDate(values.privateRemovalDate)
  if (values.privateRemovalDate && !isPrivateRemovalDateValid) {
    errors.privateRemovalDate = 'Invalid Date or Format, please use MM-DD-YYYY'
  }

  const isFittingsDateValid = isValidDate(values.fittingsVerifiedDate)
  if (values.fittingsVerifiedDate && !isFittingsDateValid) {
    errors.fittingsVerifiedDate =
      'Invalid Date or Format, please use MM-DD-YYYY'
  }

  return errors
}
