import gql from 'graphql-tag'

export const COMMUNICATION_PREVIEW = gql`
  query communicationPreview(
    $accountId: Int!
    $triggerType: String, 
    $triggerCondition: String, 
    $eventId: Int, 
    $content: String!
  ) {
    communicationPreview(
      accountId: $accountId
      triggerType: $triggerType, 
      triggerCondition: $triggerCondition, 
      eventId: $eventId, 
      content: $content
    )
  }
`

export interface CommunicationPreviewData {
  communicationPreview: string
}

export interface CommunicationPreviewVariables {
  accountId: number
  triggerType?: string
  triggerCondition?: string
  eventId?: number
  content: string
}
