import React from 'react'
import { Button } from '@120wateraudit/envirio-components'
import styled from 'styled-components'
import moment from 'moment'
import {
  useGetLatestSubmissionQuery,
  useGetSystemInfoQuery
} from 'src/services/state-submission-api'
import { PtdPwsSettings } from 'src/types/PtdPwsSettings'

interface ConfirmConsentModalProps {
  ptdPwsSettings: PtdPwsSettings
  onSaveData: (ptdPwsSettings: PtdPwsSettings, consent: boolean) => void
  closeModal: () => void
}

const ConfirmConsentModal: React.FC<ConfirmConsentModalProps> = ({
  ptdPwsSettings,
  onSaveData,
  closeModal
}) => {
  const { data: system } = useGetSystemInfoQuery()
  const { data: latestSubmissionQueryData } = useGetLatestSubmissionQuery(
    system?.id || 0
  )

  const { consentToAutoPublish } = ptdPwsSettings

  const onConsent = () => {
    onSaveData(ptdPwsSettings, true)
    closeModal()
  }

  const Consent = () => (
    <>
      <TopText>
        <b>By consenting to auto-publish, your approved inventory</b>
        <b> will all be visible on the State Public Dashboard</b>
      </TopText>
      {latestSubmissionQueryData?.data && (
        <DateText>
          <b>
            Last submission was on{' '}
            {moment(latestSubmissionQueryData.data.submittedOn).format(
              'MM/DD/YYYY'
            )}
          </b>
        </DateText>
      )}
    </>
  )

  const NotConsent = () => (
    <TopText>
      <div style={{ paddingBottom: '20px' }}>
        <b>
          By not consenting to your submissions being published to the State
          PTD, it is possible that you will be out of compliance
        </b>
      </div>
    </TopText>
  )

  return (
    <ModalWrapper>
      {consentToAutoPublish ? <Consent /> : <NotConsent />}
      <ButtonWrapper>
        <Button
          style={{ width: '45%', borderRadius: '8px' }}
          onClick={onConsent}
          variant={consentToAutoPublish ? 'primary' : 'error'}>
          {consentToAutoPublish ? 'Consent' : 'I Understand'}
        </Button>
        <Button
          style={{ width: '45%', borderRadius: '8px' }}
          onClick={closeModal}
          variant="default">
          Cancel
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div`
  margin: 1.5rem;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TopText = styled.div`
  line-height: normal;
`

const DateText = styled.div`
  padding: 20px 0px 20px 0px;
  font-weight: 800; /* Set the font weight to extra bold */
`

const ButtonWrapper = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
`

export default React.memo(ConfirmConsentModal)
